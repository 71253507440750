<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">관리자 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"> <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터 </v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="4">
                            <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder hide-details @input="filter.searchValue = null;"></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true);"></v-text-field>
                        </v-col>
                    </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search()"> <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색 </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀다운로드 </v-btn>
                </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="users" disable-sort disable-pagination hide-default-footer class="elevation-1" :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template v-slot:[`item.isWarning`]="{ item }">
                    <v-row justify="center" align="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.isWarning" color="red lighten-1" hide-details dens class="mt-0" @change="updateUser({ _id: item._id, isWarning: item.isWarning })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)"> mdi-pencil </v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { mdiMicrosoftExcel } from "@mdi/js";

const headers = [
    { text: "가입일자", align: "center", width: 130, value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { text: "아이디", align: "center", width: 130, value: "username" },
    { text: "이름", align: "center", width: 160, value: "name" },
    { text: "연락처", align: "center", width: 130, value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-" },
    { text: "이메일", align: "center", width: 160, value: "email" },
    { text: "Actions", value: "actions", width: 80, align: "center" },
];

export default {
    data() {
        return {
            mdiMicrosoftExcel,
            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "아이디", value: "username" },
                { text: "이름", value: "name" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            tabIndex: 0,

            headers,
            users: [],
            summary: { totalCount: 0 },

            levels: [],
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { levels } = await api.console.levels.gets();
                this.levels = levels;

                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            try {
                this.$router.push({
                    query: Object.assignDefined({}, this.$route.query, this.filter, {
                        page: this.page,
                    }),
                });

                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });
                
                this.users = users;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        create() {
            this.$router.push(`/console/users/create`);
        },

        edit(user) {
            this.$router.push({
                path: `/console/users/${user._id}`,
            });
        },

        async updateUser(user) {
            try {
                await api.console.users.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        async excel() {
            try {
                var { users } = await api.console.users.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.filter,
                });

                var rows = users.map((user) => ({
                    가입일자: user?.createdAt?.toDateTime?.(),
                    유형: user?.typeText,
                    아이디: user?.username,
                    "기업명/사용자명": user?.name,
                    국가: user?.nation,
                    연락처: user?.phone,
                    이메일: user?.email,
                    생년월일: user?.bornAt?.toDateTime?.(),
                    성별: user?.sex,
                    직업: user?.occupation,
                    직장: user?.companyName,
                    사업자등록번호: user?.businessRegisitrationNumber,
                    기업설립일: user?.foundAt?.toDateTime?.(),
                    대표자명: user?.ceoName,
                    홈페이지: user?.homepage,
                    주의회원여부: user?.isWarning ? "주의" : "-",
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "회원목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        async sendemail() {
            try {
                var users;
                switch (this.messageTarget) {
                    case "search": {
                        users = (
                            await api.console.users.gets({
                                params: this.filter,
                            })
                        ).users;
                        break;
                    }
                    case "selected": {
                        if (!this.selected.length) throw new Error("사용자를 선택해주세요");
                        users = this.selected;
                        break;
                    }
                }

                var { success } = await api.v1.message.post({
                    emails: users.map((user) => user.email),
                    subject: this.messageSubject,
                    message: this.message,
                });

                if (success) alert("발송되었습니다");
            } catch (error) {
                alert(error.message);
            }
        },

        upload(file, type) {
            if (!file) return;

            let reader = new FileReader();
            reader.onload = () => {
                var workSheets = [];
                var workBook = XLSX.read(reader.result, { type: "binary" });
                workBook.SheetNames.forEach((sheetName) => {
                    let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    workSheets.push(sheet);
                });
                this.$refs.uploader.excelSheet = workSheets[0]; // 첫번째 시트
                this.$refs.uploader.type = type;
                this.$refs.uploader.open();
            };
            reader.readAsBinaryString(file);
        },
    },
};
</script>
