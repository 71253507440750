<template>
    <v-btn-toggle v-model="value">
        <v-btn :value="null">전체</v-btn>
        <v-btn v-for="{ text, value } in categories" v-bind="{ value }" :key="value"> {{ text }} </v-btn>
    </v-btn-toggle>
</template>

<script>
import { BOARD_FORMS_CATEGORIES } from "@/assets/variables";
const categories = Object.values(BOARD_FORMS_CATEGORIES);

export default {
    data: () => ({
        value: null,
        categories,
    }),
    computed: {
        category() {
            return this.$route.query.category || null;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        category() {
            if (this.value !== this.category) this.init();
        },
        value() {
            if (this.value !== this.category) {
                console.log();
                this.$router.push({ query: { ...this.$route.query, category: this.value || undefined, page: 1 } });
            }
        },
    },
    methods: {
        init() {
            this.value = this.category;
        },
    },
};
</script>

<style></style>
