<template>
    <v-responsive v-if="ready">
        <v-card class="mt-4">
            <v-card-title class="subtitle-1">카테고리</v-card-title>
            <v-card-text>
                <category-select v-model="product._category" :categories="categories" @input="input"></category-select>
            </v-card-text>
        </v-card>

        <v-row class="mt-4">
            <v-col cols="12" md="7" sm="7">
                <v-card>
                    <v-card-title class="subtitle-1 font-weight-bold">썸네일</v-card-title>
                    <v-card-text>
                        <product-image v-model="product.thumb" label="썸네일" @remove="removeThumb" @input="input"></product-image>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-1 font-weight-bold">상품 이미지</v-card-title>
                    <v-card-text>
                        <draggable v-model="product.images">
                            <transition-group>
                                <product-image v-for="(i, index) in 5" v-model="product.images[index]" :label="`슬라이드${i}`" :key="`${index}`" @remove="removeImage" @input="input"></product-image>
                            </transition-group>
                        </draggable>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        선정정보
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="product.awardKind" label="선정구분" persistent-placeholder hide-details class="mt-3"></v-text-field>
                        <v-text-field v-model="product.awardNumber" label="선정번호" persistent-placeholder hide-details class="mt-3 mb-6"></v-text-field>
                        <v-date-field v-model="product.awardAt" label="선정일" persistent-placeholder hide-details></v-date-field>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        URL 링크
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="product.site" label="판매 사이트" persistent-placeholder hide-details class="mt-3"></v-text-field>
                        <v-text-field v-model="product.homepage" label="홍페이지" persistent-placeholder hide-details class="mt-3"></v-text-field>
                        <v-text-field v-model="product.promotion" label="홍보마당 URL" persistent-placeholder hide-details class="mt-3"></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="5" sm="5">
                <v-card>
                    <v-card-title>
                        <v-row align="start">
                            <v-col cols="auto" class="subtitle-2 font-weight-bold">
                                상품 정보
                            </v-col>
                            <v-spacer />
                            <v-col>
                                <v-select v-model="locale" :items="locales" item-text="text" item-value="value" label="언어" persistent-placeholder dense hide-details class="mt-0"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="product.name[locale]" label="상품명" persistent-placeholder @input="input"></v-text-field>
                        <v-textarea v-model="product.summary[locale]" label="주요특징" persistent-placeholder @input="input"></v-textarea>
                        <v-text-field v-model="product.country[locale]" label="제조국" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.size[locale]" label="사이즈" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.color[locale]" label="칼라" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.elements[locale]" label="전성분" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.onlineDistributor[locale]" label="주요판로처(온라인)" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.offlineDistributor[locale]" label="주요판로처(오프라인)" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.manufacture" label="제조방법" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.company" label="제조업체" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field :value="$dayjs(product.updatedAt).format('YYYY년 MM월 DD일 (ddd) HH:mm')" label="최종수정일" disabled></v-text-field>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-text>
                        <v-text-field v-model="product.price" label="소비자가(KRW)" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.salePrice" label="판매가(KRW)" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.salesAmount" label="매출액(KRW)" persistent-placeholder @input="input"></v-text-field>
                        <v-text-field v-model="product.exportsAmount" label="수출액($)" persistent-placeholder @input="input"></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-responsive>
</template>

<script>
import api from "@/api";

import draggable from 'vuedraggable'
import NaverSmarteditor from '@/components/plugins/naver/naver-smarteditor.vue';

import CategorySelect from '@/components/console/shop/categories/category-select.vue';
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

import ProductImage from "@/components/console/shop/products/product-image.vue";

export default {
    components: {
        draggable,
        NaverSmarteditor,
        ProductImage,
        CategorySelect,
        VDateField,
    },
    props: {
        value: { type: Object, default: null }
    },
    data(){
        return {
            ready: null,

            product: this.$props.value,

            hashtag: null,
            brands: [],
            categories: [],

            locale: "ko",
            locales: [
                { text: "한국어", value: "ko" },
                { text: "영어", value: "en" },
                { text: "중국어", value: "cn" },
            ]
        }
    },
    mounted(){
        this.init();
    },

    methods: {
        async init(){
            var { categories } = await api.console.shop.categories.gets();
            this.categories = categories;

            if(this.product._id === undefined){
                var { summary } = await api.console.shop.products.gets({ headers: { limit: 1 } });
                this.product.sequence = summary.totalCount + 1;
            }

            if(this.$route.query._product){
                this.product._id = undefined;

                for(var option of this.product.options){
                    option._id = undefined;
                }
                for(var supply of this.product.supplies){
                    supply._id = undefined;
                }
            }

            this.input();
            this.ready = true;
        },

        input(){
            this.$emit("input", this.product);
        },

        async removeThumb(){
            try{
                if(confirm("상품 썸네일을 삭제하시겠습니까?")){
                    if(this.product._id) await api.console.shop.products.thumb.delete(this.product._id);
                    this.product.thumb = null;
                    this.$emit("input", this.product);
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async removeImage(index){
            try{
                if(confirm("상품 이미지를 삭제하시겠습니까?")){
                    if(this.product._id) await api.console.shop.products.images.delete(this.product._id, index);
                    this.product.images[index] = undefined;
                    this.$forceUpdate();
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        
    },
    computed: {
        optionsStock(){
            var stock = 0;
            for(var i in this.product.options){
                stock += this.product.options[i].stock;
            }
            return stock;
        }
    }
}
</script>

<style>
.shop-product-view .weight input { text-align: end; }
.shop-product-view .amount input { text-align: end; }
.shop-product-view .theme--light.v-tabs-items { background-color: transparent !important; }
.shop-product-view .option-groups-headers th { border-bottom: thin solid rgba(0,0,0,.12) }
</style>
