<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">
            <v-row class="headline text-start mt-8">
                <v-col cols="auto" class="py-0">
                    <span>{{ banner._id ? "배너 상세보기" : "배너 등록" }}</span>
                </v-col>
                <v-spacer />
                <!-- <v-col cols="auto" class="py-0">
                    <v-select v-model="banner.locale" :items="locales" item-text="text" item-value="value" label="언어" persistent-placeholder outlined dense hide-details class="mt-0"></v-select>
                </v-col> -->
            </v-row>

            <v-row>
                <v-col cols="12" md="8">
                    <v-card>
                        <v-card-title class="subtitle-2 font-weight-bold">슬라이드</v-card-title>
                        <v-card-text>
                            <draggable v-model="banner.slides">
                                <transition-group>
                                    <v-list-item v-for="(slide, index) in banner.slides" :key="`${index}`">
                                        <v-list-item-action>
                                            <v-preview-input v-model="slide.image" min-width="96" min-height="96" max-width="96" max-height="96" contain></v-preview-input>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="slide.meta.title" label="제목" persistent-placeholder hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="slide.meta.location" label="장소" persistent-placeholder hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="slide.meta.date" label="일자" persistent-placeholder hide-details type="date"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="slide.url" label="URL" persistent-placeholder hide-details></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon @click="banner.slides.splice(index, 1)">mdi-delete</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </transition-group>
                            </draggable>
                        </v-card-text>
                    </v-card>

                    <v-file-btn outlined color="blue" width="100%" multiple accept="image/*" class="mt-3" @change="upload">
                        <v-icon class="mr-2">{{ mdiCloudUpload }}</v-icon>
                        <span>배너 이미지 업로드</span>
                    </v-file-btn>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="pa-3">
                        <v-text-field v-model="banner.name" label="이름" persistent-placeholder hide-details></v-text-field>
                    </v-card>
                    <v-card class="pa-3 mt-3">
                        <v-text-field v-model="banner.code" label="코드" persistent-placeholder hide-details></v-text-field>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
        <daum-postcode
            ref="DaumPostcode"
            @input="
                ({ postcode, address }) => {
                    banner.postcode = postcode;
                    banner.address1 = address;
                }
            "
        ></daum-postcode>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiCloudUpload } from "@mdi/js";
import draggable from "vuedraggable";

import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        draggable,

        VPreviewInput,
        VFileBtn,

        DaumPostcode,
        NaverSmarteditor,
        VDateField,
    },
    data() {
        return {
            mdiCloudUpload,
            // locales: [
            //     { text: "한국어", value: "ko" },
            //     { text: "영어", value: "en" },
            //     { text: "중국어", value: "cn" },
            // ],

            images: null,

            banner: {
                _id: null,

                name: null,
                code: null,
                locale: "ko",
                slides: [],
            },

            closedAt: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.$route.params._banner) {
                var { banner } = await api.console.banners.get({ _id: this.$route.params._banner });

                for (let index in banner.slides) {
                    if (banner.slides[index].image) banner.slides[index].image = await api.getResource(banner.slides[index].image);
                }

                this.banner = { ...this.banner, ...banner };
            }

            if (!this.banner.slides.length) {
                this.banner.slides = [...this.banner.slides, { image: null, url: null, meta: {} }];
            }
        },

        validate() {
            try {
                if (!this.banner.name) throw new Error("배너 명칭을 입력해주세요");
                if (!this.banner.code) throw new Error("배너 코드를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                let { banner } = this.banner._id ? await api.console.banners.put(this.banner) : await api.console.banners.post(this.banner);

                for (let index in this.banner.slides) {
                    if (this.banner.slides[index]) await api.console.banners.images.post(banner._id, index, this.banner.slides[index].image);
                }

                alert("저장되었습니다");
                this.$router.push(`/console/banners`);
            }
        },

        upload(files) {
            this.banner.slides = [...files.map((file) => ({ image: file, url: null, meta: {} })), ...this.banner.slides];
        },
    },
};
</script>

<style>
.editor.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
</style>
