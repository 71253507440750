<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="px-1">
            <div class="headline text-start mt-8">
                <span v-if="user._id">관리자 상세</span>
                <span v-else>관리자 등록</span>
            </div>

        <v-card class="mx-1 mt-3">
            <v-card-title class="tit tit--xs">기본정보</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.username" label="아이디" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-password-field v-model="user.password" label="비밀번호" persistent-placeholder hide-details></v-password-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.name" label="담당자(이름)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.phone" label="담당자(연락처)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="user.email" label="담당자(이메일)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.postcode" label="우편번호" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="user.address1" label="기본주소" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="user.address2" label="상세주소" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions />
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

            <v-row justify="center" class="mx-1 my-4">
                <v-col md="auto" sm="auto"> </v-col>
            </v-row>
        </v-responsive>
        <daum-postcode ref="DaumPostcode" @input="setAddress"></daum-postcode>
    </v-layout>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_NOTIFY_METHODS, USER_TYPES } from "@/assets/variables";
const { COMPANY, PERSON } = USER_TYPES;
const notifyMethods = Object.values(USER_NOTIFY_METHODS);

const headers_notify = [
    { text: "유형", value: "method", formatter: (value) => notifyMethods.find((item) => item.value == value)?.text },
    { text: "연락처", value: "contact" },
    { text: "수신동의", value: "enabled", formatter: (value) => (value ? "동의" : "미동의") },
];

export default {
    components: {
        DaumPostcode,
        VPasswordField
    },
    data() {
        return {
            levels: [],
            user: {
                _id: this.$route.params._user,
            },

            window,
            PERSON,
            COMPANY,
            headers_notify,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.user._id) {
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                if (user?.bornAt) user.bornAt = user.bornAt.toDate();
                if (user?.foundAt) user.foundAt = user.foundAt.toDate();
                this.user = user;
            }

            var { levels } = await api.console.levels.gets();
            this.levels = levels;
        },

        validate() {
            try {
                // if(!this.user.name) throw new Error("담당자 이름을 입력해주세요");
                // if(!this.user.email) throw new Error("담당자 이메일을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                if (this.user._id) {
                    await api.console.users.put({
                        ...this.user,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                } else {
                    await api.console.users.post({
                        ...this.user,
                        username: this.user.email,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                }

                alert("저장되었습니다");
                this.$router.push(`/console/users`);
            }
        },

        setAddress({ postcode, address }) {
            this.user.postcode = postcode;
            this.user.address1 = address;
        },
    },
};
</script>
