<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <div v-if="!$route.params._product" class="headline text-start mt-8">상품 등록</div>
            <div v-else class="headline text-start mt-8">상품 상세</div>

            <v-tabs v-model="tab" background-color="content" class="mt-4">
                <v-tab key="basic">기본상품정보</v-tab>
                <v-tab-item key="basic" >
                    <product-basic-info v-model="product"></product-basic-info>
                </v-tab-item>
            </v-tabs>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ProductBasicInfo from "@/components/console/shop/products/product-basic-info.vue";

export default {
    components: {
        ProductBasicInfo
    },
    data() {
        return {
            ready: false,
            tab: "basic",

            ///////////////////////////////////////////////
            // 상품정보
            ///////////////////////////////////////////////
            product: {
                _category: null,
                sequence: 0,

                code: null,
                name: { ko: null, en: null, cn: null },
                content: { ko: null, en: null, cn: null },
                country: { ko: null, en: null, cn: null },
                manufacture: null,
                company: null,
                size: null,
                color: { ko: null, en: null, cn: null },
                elements: { ko: null, en: null, cn: null },
                distributor: { ko: null, en: null, cn: null },

                site: null,
                homepage: null,
                promotion: null,

                price: 0,
                salesAmount: 0,
                exportsAmount: 0,
                
                images: new Array(2),
            },
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.$route.params._product || this.$route.query._product){
                var { product } = await api.console.shop.products.get({ _id: this.$route.params._product || this.$route.query._product });
                try{
                    product.thumb = await api.getResource(product.thumb);
                    for(let index = 0; index < 5; index ++){
                        product.images[index] = await api.getResource(product.images[index]);
                    }
                }
                catch(error){
                    console.error(error);
                }
                this.product = product;
            }


            this.ready = true;
        },
        async save(){
            try{
                ////////////////////////////////////////////////////////////////
                // 1. 상품저장
                ////////////////////////////////////////////////////////////////
                var { product } = this.product._id ? await api.console.shop.products.put(this.product) : await api.console.shop.products.post(this.product);

                ////////////////////////////////////////////////////////////////
                // 4. 썸네일 저장
                ////////////////////////////////////////////////////////////////
                if(this.product.thumb) await api.console.shop.products.thumb.post(product._id, this.product.thumb);

                ////////////////////////////////////////////////////////////////
                // 5. 이미지 저장
                ////////////////////////////////////////////////////////////////
                for(var index in this.product.images){
                    if(this.product.images[index]) await api.console.shop.products.images.post(product._id, index, this.product.images[index]);
                }

                alert(this.product._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
                
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
}
</script>
