<template>
    <v-dialog v-model="show" max-width="1280">
        <v-card v-if="excelSheet">
            <v-card-title class="subtitle-1">상품 업로드</v-card-title>
            <v-divider/>
            <v-card-text class="pa-0">
                <v-data-table :items="products" :headers="headers">
                </v-data-table>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="grey" text @click="cancel">취소</v-btn>
                <v-btn color="primary" text @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import api from "@/api";
export default {
    data(){
        return {
            show: false,

            type: null,
            excelSheet: null,

            categories: [],
            sellers: [],

            headers: [
                {
                    text: "상품코드",
                    align: "center",
                    width: 200,
                    value: "_id",
                },
                {
                    text: "셀러",
                    align: "center",
                    width: 200,
                    value: "seller.companyName.ko",
                },
                {
                    text: "사업자번호",
                    align: "center",
                    width: 200,
                    value: "seller.businessNumber",
                },
                {
                    text: "상품명",
                    align: "center",
                    width: 200,
                    value: "name.ko",
                },
                {
                    text: "상품명-영문",
                    align: "center",
                    width: 200,
                    value: "name.en",
                },
                {
                    text: "상품명-중문",
                    align: "center",
                    width: 200,
                    value: "name.cn",
                },
                {
                    text: "썸네일 URL",
                    align: "center",
                    width: 200,
                    value: "thumb",
                },
                {
                    text: "상품사진2 URL",
                    align: "center",
                    width: 200,
                    value: "images[0]",
                },
                {
                    text: "카테고리-대분류",
                    align: "center",
                    width: 200,
                    value: "category.name.ko",
                },
                {
                    text: "카테고리-중분류",
                    align: "center",
                    width: 200,
                    value: "subcategory.name.ko",
                },
                {
                    text: "주요특징",
                    align: "center",
                    width: 500,
                    value: "summary.ko",
                },
                {
                    text: "주요특징-영문",
                    align: "center",
                    width: 500,
                    value: "summary.en",
                },
                {
                    text: "주요특징-중문",
                    align: "center",
                    width: 500,
                    value: "summary.cn",
                },
                {
                    text: "제조국",
                    align: "center",
                    width: 200,
                    value: "country.ko",
                },
                {
                    text: "제조국-영문",
                    align: "center",
                    width: 200,
                    value: "country.en",
                },
                {
                    text: "제조국-중문",
                    align: "center",
                    width: 200,
                    value: "country.cn",
                },
                {
                    text: "컬러",
                    align: "center",
                    width: 200,
                    value: "color.ko",
                },
                {
                    text: "컬러-영문",
                    align: "center",
                    width: 200,
                    value: "color.en",
                },
                {
                    text: "컬러-중문",
                    align: "center",
                    width: 200,
                    value: "color.cn",
                },
                {
                    text: "사이즈",
                    align: "center",
                    width: 200,
                    value: "size.ko",
                },
                {
                    text: "사이즈(영문)",
                    align: "center",
                    width: 200,
                    value: "size.en",
                },
                {
                    text: "사이즈(중문)",
                    align: "center",
                    width: 200,
                    value: "size.cn",
                },
                {
                    text: "전 성분",
                    align: "center",
                    width: 800,
                    value: "elements.ko",
                },
                {
                    text: "전 성분-영문",
                    align: "center",
                    width: 500,
                    value: "elements.en",
                },
                {
                    text: "전 성분-중문",
                    align: "center",
                    width: 500,
                    value: "elements.cn",
                },
                {
                    text: "주요판로처(온라인)",
                    align: "center",
                    width: 200,
                    value: "onlineDistributor.ko",
                },
                {
                    text: "주요판로처(온라인)-영문",
                    align: "center",
                    width: 200,
                    value: "onlineDistributor.en",
                },
                {
                    text: "주요판로처(온라인)-중문",
                    align: "center",
                    width: 200,
                    value: "onlineDistributor.cn",
                },
                {
                    text: "주요판로처(오프라인)",
                    align: "center",
                    width: 200,
                    value: "offlineDistributor.ko",
                },
                {
                    text: "주요판로처(오프라인)-영문",
                    align: "center",
                    width: 200,
                    value: "offlineDistributor.en",
                },
                {
                    text: "주요판로처(오프라인)-중문",
                    align: "center",
                    width: 200,
                    value: "offlineDistributor.cn",
                },
                {
                    text: "제조방법",
                    align: "center",
                    width: 500,
                    value: "manufacture",
                },
                {
                    text: "제조업체",
                    align: "center",
                    width: 200,
                    value: "company",
                },
                {
                    text: "소비자가",
                    align: "center",
                    width: 200,
                    value: "price",
                },
                {
                    text: "판매가",
                    align: "center",
                    width: 200,
                    value: "salePrice",
                },
                {
                    text: "국내매출액(최근1년)",
                    align: "center",
                    width: 200,
                    value: "salesAmount",
                },
                {
                    text: "수출액(최근1년)",
                    align: "center",
                    width: 200,
                    value: "exportsAmount",
                },
                {
                    text: "판매사이트",
                    align: "center",
                    width: 200,
                    value: "site",
                },
                {
                    text: "홈페이지",
                    align: "center",
                    width: 200,
                    value: "homepage",
                },
                {
                    text: "SMC홍보마당URL",
                    align: "center",
                    width: 200,
                    value: "promotion",
                },
                {
                    text: "인증구분",
                    align: "center",
                    width: 200,
                    value: "awardKind",
                },
                {
                    text: "인증번호",
                    align: "center",
                    width: 200,
                    value: "awardNumber",
                },
                {
                    text: "선정일",
                    align: "center",
                    width: 200,
                    value: "awardAt",
                },
                {
                    text: "출시일자",
                    align: "center",
                    width: 200,
                    value: "publishingAt",
                },
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { users } = await api.console.users.gets({
                headers: { limit: 0 },
                params: { type: "seller" }
            });

            this.sellers = users;

            let { categories } = await api.console.shop.categories.gets({
                headers: { limit: 0 },
                params: { depth: 1 }
            });
            this.categories = categories;
            console.log(categories);
        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        cancel(){
            this.excelSheet = [];
            this.close();
        },
        async save(){
            for(var product of this.products){
                product._id ? await api.console.shop.products.put(product) : await api.console.shop.products.post(product);
            }
            this.$emit("save");
            this.close();
        },
    },
    computed: {
        products(){
            let products = [];
            let dayjs = this.$dayjs;

            for(let row of this.excelSheet){
                let seller = this.sellers.find(seller => seller.businessNumber == row["사업자등록번호"]);
                let category = this.categories.find(category => category.name.ko == row["카테고리-대분류"]);
                let subcategory = category?.children?.find(category => category.name.ko == row["카테고리-중분류"]);

                products.push({
                    _seller: seller?._id,
                    _category:  subcategory?._id,

                    seller,
                    category,
                    subcategory,

                    _id: row["상품코드"],

                    name: {
                        ko: row["상품명"],
                        en: row["상품명-영문"],
                        cn: row["상품명-중문"],
                    },
                    summary: {
                        ko: row["주요특징"],
                        en: row["주요특징-영문"],
                        cn: row["주요특징-중문"],
                    },
                    country: {
                        ko: row["제조국"],
                        en: row["제조국-영문"],
                        cn: row["제조국-중문"],
                    },
                    color: {
                        ko: row["컬러"],
                        en: row["컬러-영문"],
                        cn: row["컬러-중문"],
                    },
                    size: {
                        ko: row["사이즈"],
                        en: row["사이즈(영문)"],
                        cn: row["사이즈(중문)"]
                    },
                    elements: {
                        ko: row["전 성분"],
                        en: row["전 성분-영문"],
                        cn: row["전 성분-영문"],
                    },
                    onlineDistributor: {
                        ko: row["주요판로처(온라인)"],
                        en: row["주요판로처(온라인)-영문"],
                        cn: row["주요판로처(온라인)-중문"],
                    },
                    offlineDistributor: {
                        ko: row["주요판로처(오프라인)"],
                        en: row["주요판로처(오프라인)-영문"],
                        cn: row["주요판로처(오프라인)-중문"],
                    },

                    manufacture: row["제조방법"],
                    company: row["제조업체"],

                    price: row["소비자가"] || 0,
                    salePrice: row["판매가"] || 0,
                    salesAmount: row["국내매출액(최근1년)"] ||0,
                    exportsAmount: row["수출액(최근1년)"] || 0,

                    thumb: row["썸네일 URL"],
                    images: [row["상품사진1 URL"]],

                    site: row["판매사이트"],
                    homepage: row["홈페이지"],
                    promotion: row["SMC홍보마당URL"],

                    awardKind: row["인증구분"],
                    awardAt: row["선정일"] ? this.$dayjs(row["선정일"]).add(1, "day").format("YYYY-MM-DD") : "",
                    awardNumber: row["인증서번호"],

                    publishingAt: row["출시일자"] ? this.$dayjs(row["출시일자"]).add(1, "day").format("YYYY-MM-DD") : ""
                });
            }
            return products;
        }
    }
}
</script>